import {Col, Row, Space} from "antd";
import React from "react";

function Footer() {
    return (
        <>
            <Row justify={"center"}>
                <Space size={"middle"}>
                    <h5 style={{marginBottom: 0}}><a href="/privacy-policy.html"
                                                     style={{textDecoration: "none", color: '#B99095'}}>Privacy
                        policy</a></h5>
                    <h5 style={{marginBottom: 0}}><a href="/terms-and-conditions.html"
                                                     style={{textDecoration: "none", color: '#B99095'}}>Terms and
                        Conditions</a></h5>
                    <h5 style={{marginBottom: 0}}><a href="/shipping-policy.html"
                                                     style={{textDecoration: "none", color: '#B99095'}}>Shipping
                        Policy</a></h5>
                </Space>
            </Row>
            <Row justify={"center"} style={{backgroundColor: "#B99095", marginTop: "2em", paddingBottom: "-1px"}}>
                <Col>
                    <h5 style={{color: "#fff"}}>© 2025 L'Atelier de Macaron</h5>
                </Col>
            </Row>
        </>
    )
}

export default Footer;