import React, {FC} from "react";
import {Rate} from "antd";
import {MacaronIcon} from "../../main/component/MacaronIcon";
import {ClientOrder} from "../model/ClientOrder";

interface AlertBoxProps {
    boxSize: number;
    ordered: number;
}

export const Box: FC<AlertBoxProps> = ({boxSize, ordered}) => {
    let rows = Math.ceil(boxSize / 10);
    let countInRow = Math.ceil(boxSize / rows);
    let lastRowCount = boxSize - countInRow * (rows - 1);
    let rates: (JSX.Element | null)[] = [];
    for (let i = 0; i < rows; i++) {
        let currentCountInRow = i === rows - 1 ? lastRowCount : countInRow;
        rates.push(
            <Rate
                key={i}
                style={{color: '#B99095'}}
                disabled={true}
                count={currentCountInRow}
                value={ordered > countInRow * (i + 1) ? currentCountInRow : Math.max(0, ordered - countInRow * i)}
                character={<MacaronIcon/>}/>
        );
        if (i < rows - 1) {
            rates.push(<br key={`br-${i}`}/>);
        }
    }
    return <>{rates}</>;
}

interface ShowBoxProps {
    clientOrder: ClientOrder;
}

export const ShowBox: FC<ShowBoxProps> = ({clientOrder}) => {
    return <Box boxSize={clientOrder.boxSize} ordered={clientOrder.added}/>
}